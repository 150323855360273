














import { Component, Vue, Watch } from "vue-property-decorator";
import {
  Message,
  Loading,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import Request from "./services/request";
import { tokenStore } from "./store/LocalStorage";
@Component({
  components: {
    Message,
    Loading,
  },
})
export default class App extends Request {
  logoLight = require("@/assets/logos-new/LOGO 11.png");
  logoDark = require("@/assets/logos-new/LOGO 1.png");
  find_max(nums) {
    let max_num = Number.NEGATIVE_INFINITY;
    for (let num of nums) {
      if (num > max_num) {
        // (Fill in the missing line here)
      }
    }
    return max_num;
  }
  async mounted() {
    localStorage.setItem("logo-dark", this.logoDark);
    localStorage.setItem("logo-light", this.logoLight);
    if (
      !localStorage.getItem("themeColor") &&
      !localStorage.getItem("themeColor") &&
      !localStorage.getItem("themeColor")
    ) {
      localStorage.setItem("themeColor", "#1e88e5");
      localStorage.setItem("sideBarColor", "#121212");

      localStorage.setItem("navBarColor", "#1e88e5");
    }
    Vue.prototype.$message = this.$refs.message;
    Vue.prototype.$loading = this.$refs.loading;
  }
  get cssProps() {
    return {
      "--primary-color": this.$vuetify.theme.themes.dark.primary,
    };
  }
  @Watch("$route")
  async update() {
    const localToken = tokenStore.get();
    if (localToken) {
      const result = await this.post(this.paths.authorizePath, {
        accessToken: localToken,
      });
      return result;
    }
  }
}
